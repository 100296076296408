import React from 'react';
import {cpfMask, cnpjMask, celularMask, cepMask, identidadeMask, realMoeda, apenasNumeros} from '../InputTextMask/index'
function dommusMaskedForm (Form){

	return class extends React.Component{

		render(){
			const methods = {
				cpfMask: cpfMask,
				cnpjMask: cnpjMask,
				celularMask: celularMask,
				cepMask: cepMask,
				identidadeMask: identidadeMask,
				realMoeda: realMoeda,
				apenasNumeros: apenasNumeros
			};

			return <Form {...this.props} {...methods} />;
		}

	}

}

export default dommusMaskedForm;