function apenasNumeros(value){
    return value.replace(/[^0-9]/g, '');
}

function cpfMask (value){
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

function cnpjMask (value) {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1/$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

function celularMask (value) {
    const onlyDigits = value.replace(/[^0-9]/g, '');
    let searchPrimeiroSegmento = /(\d{4})(\d)/;
    if(onlyDigits.length > 10){
        searchPrimeiroSegmento = /(\d{5})(\d)/;
    }

    return onlyDigits
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(searchPrimeiroSegmento, '$1-$2')
      //.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
}

function cepMask (value) {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

function pisMask (value) {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{5})(\d)/, '$1.$2')
    .replace(/(\d{5}\.)(\d{2})(\d)/, '$1$2-$3')
    .replace(/(-\d{1})\d+?$/, '$1')
}

function identidadeMask (value) {
    return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{1})\d+?$/, '$1')
}

function realMoeda (value) {
    String(value)
    
        value = value 
            .replace(/\D/g, '')
            .replace(/([0-9]{2})$/g, ",$1")
        
        if( value.length > 6 )
           value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

        return value
  
    //.replace(/(\d{1})(\d{3})(\d{2})$/g, "$1.$2,$3")
    //.replace(/([0-9]{2})$/g, ",$1")
      
    // if(value.length > 6){
       
    //     value
    //         .replace(/\D/g, '')
    //         .replace(/(\d{1})(\d{3})(\d{2})$/g, "$1.$2,$3")

    //     return value
    // }else{
    //     value
    //         .replace(/\D/g, '')
    //         .replace(/([0-9]{2})$/g, ",$1")

    //     return value
    // }

    //console.log("value length", value.length)

    // return value
    //     .replace(/\D/g, '')
    //     .replace(/(\d{1})(\d{3})(\d{2}$)/g, "$1.$2,$3");

    //.replace(/(\d{3})(\d{2})(\d)/, "$1$2.$3")
    //.replace(/(\d{3}),(\d{2}$)/g, "$1.$2,");
    //.replace(/(\d{2})(\d)/, '$1,$2')
    //.replace(/(\d{3})(\d)/, '$1.$2')
    //.replace(/([0-9]{2})$/g, ",$1")
    //.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    //.replace(/([0-9]{3}),([0-9]{2})(\d$)/g, ".$1,$2");
    // debugger
    // if(value != Number ){
    //     return value 
    //     .toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    // }else{
    //     return value
    //     .toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    // }
    // return value
    // .toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})

}

export {
    cpfMask,
    cnpjMask,
    celularMask,
    cepMask,
    pisMask,
    identidadeMask,
    realMoeda,
    apenasNumeros
}


//Js puro
// document.querySelectorAll('input').forEach(($input)) => {
//     const field = $input.dataset.js

//     $input.addEventListener('input', (e) => {
//         e.target.value = masks[field](e.target.value)
//     }, false)
// })