import React from "react";
import dommusMaskedForm from "../dommus-masked-form/dommus-masked-form";
import { useForm } from "react-hook-form";

function FormularioLead(props) {
  const submitLead = (data) => {
    const dadosLead = Object.assign({}, data);
    dadosLead.telefone = props.apenasNumeros(dadosLead.telefone);
    props.submitHandler(dadosLead);
  };

  const { register, handleSubmit, setValue, errors } = useForm();

  const estiloBotao = {
    background: props.corBotao || "#007bff",
    color: props.corTextoBotao || "white",
  };

  const estiloFormulario = {
    background: props.corFundo || "",
    borderColor: props.corBorda || "#dee2e6",
    borderWidth: props.tamanhoBorda || "0px",
    borderStyle: "solid",
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <form
            className="align-content-center rounded p-2"
            style={estiloFormulario}
            onSubmit={handleSubmit(submitLead)}
          >
            {Object.values(props.camposQuestionario).map((item) => {
              return item.value === "nome" ? (
                <div key={item.value} className="form-group">
                  <label htmlFor="nome">{item.label}:</label>
                  <input
                    className="form-control"
                    type="text"
                    name="nome"
                    id="nome"
                    ref={register({
                      required: props.camposObrigatorios.includes("nome"),
                    })}
                  />
                  {errors.nome && (
                    <small className="form-text text-danger">
                      Informe seu nome.
                    </small>
                  )}
                </div>
              ) : item.value === "email" ? (
                <div key={item.value} className="form-group">
                  <label htmlFor="email">{item.label}:</label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="email"
                    ref={register({
                      required: props.camposObrigatorios.includes("email")
                        ? "Email inválido"
                        : false,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                    })}
                  />
                  {errors.email && (
                    <small className="form-text text-danger">
                      Email inválido.
                    </small>
                  )}
                </div>
              ) : item.value === "telefone" ? (
                <div key={item.value} className="form-group">
                  <label htmlFor="telefone">{item.label}:</label>
                  <input
                    className="form-control"
                    type="text"
                    name="telefone"
                    id="telefone"
                    ref={register({
                      required: props.camposObrigatorios.includes("telefone"),
                    })}
                    onChange={(e) => {
                      setValue("telefone", props.celularMask(e.target.value));
                    }}
                  />
                  {errors.telefone && (
                    <small className="form-text text-danger">
                      Informe seu telefone
                    </small>
                  )}
                </div>
              ) : item.value === "cpf" ? (
                <div key={item.value} className="form-group">
                  <label htmlFor="telefone">{item.label}:</label>
                  <input
                    className="form-control"
                    type="text"
                    name="cpf"
                    id="cpf"
                    ref={register({
                      required: props.camposObrigatorios.includes("cpf"),
                    })}
                    onChange={(e) => {
                      setValue("cpf", props.cpfMask(e.target.value));
                    }}
                  />
                  {errors.cpf && (
                    <small className="form-text text-danger">
                      Informe seu cpf
                    </small>
                  )}
                </div>
              ) : item.value === "texto" ? (
                <div key={item.value} className="form-group">
                  <label htmlFor="telefone">{item.label}:</label>
                  <input
                    className="form-control"
                    type="text"
                    name="cpf"
                    id="cpf"
                    ref={register({
                      required: props.camposObrigatorios.includes("texto"),
                    })}
                    onChange={(e) => {
                      setValue(`${item.label}`, e.target.value);
                    }}
                  />
                </div>
              ) : item.value === "contato_whatsapp" ? (
                <div key={item.value} className="form-group">
                  <label htmlFor="telefone">{item.label}:</label>
                  <input
                    className="form-control"
                    type="text"
                    name="telefone"
                    id="telefone"
                    ref={register({
                      required:
                        props.camposObrigatorios.includes("contato_whatsapp"),
                    })}
                    onChange={(e) => {
                      setValue("telefone", props.celularMask(e.target.value));
                    }}
                  />
                  {errors.contato_whatsapp && (
                    <small className="form-text text-danger">
                      Informe seu WhatsApp
                    </small>
                  )}
                </div>
              ) : (
                <></>
              );
            })}
            <div className="d-flex justify-content-end">
              <input
                type="submit"
                className="btn"
                style={estiloBotao}
                value="Enviar"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default dommusMaskedForm(FormularioLead);
