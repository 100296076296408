import React, { Suspense } from "react";
import CrmClientService from "../crm-service/crm-client-service";
import FormularioLead from "../formulario-lead/formulario-lead";
import swal from "@sweetalert/with-react";
import DommusLoading from "../dommus-loading/DommusLoading";
import { trackPromise } from "react-promise-tracker";
import { Helmet } from "react-helmet";

const templateMap = {
  TemplateBasico: () => {
    return React.lazy(() => import("../template-basico/TemplateBasico"));
  },
};

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: null,
    };
  }

  componentDidMount() {
    const url = window.location.href;
    const arrayUrl = url.split("/");
    const hash = arrayUrl.pop();
    this.submitHandler = this.submitHandler.bind(this);
    trackPromise(
      CrmClientService.getDadosCampanha(hash).then((response) => {
        const Componente =
          templateMap[response.data.template.componente].apply();
        const opcoesCustomizacao = Object.assign(
          {},
          response.data.opcoes_customizacao
        );
        const camposQuestionario = response.data.campos_questionario || [];
        const camposObrigatorios = response.data.campos_obrigatorios || [];

        this.setState({
          template: Componente,
          opcoesCustomizacao: opcoesCustomizacao,
          camposQuestionario: camposQuestionario,
          camposObrigatorios: camposObrigatorios,
          idInquilino: response.data.inquilino,
          idCampanha: response.data.campanha,
          idMidia: response.data.midia,
        });
      })
    );
  }

  submitHandler(dadosLead) {
    dadosLead.campanha = {
      id_campanha: this.state.idCampanha,
    };
    dadosLead.midia = {
      id_midia: this.state.idMidia,
    };
    trackPromise(
      CrmClientService.inserirLead(this.state.idInquilino, dadosLead).then(
        (response) => {
          swal(
            "Cadastrado!",
            this.state.opcoesCustomizacao.mensagem_retorno ||
              "Agora é só aguardar; nossa equipe de vendas fará contato em breve.",
            "success"
          ).then(() => {
            if (this.state.opcoesCustomizacao.link_encaminhamento || false) {
              window.location.href =
                this.state.opcoesCustomizacao.link_encaminhamento;
            }
          });
        },
        (error) => {
          swal(
            "Ooops...",
            "Ocorreu um erro ao efetuar seu cadastro. Tente novamente mais tarde.",
            "error"
          );
        }
      )
    );
  }

  render() {
    const Template = this.state.template;
    return (
      <Suspense fallback={<DommusLoading />}>
        {this.state.opcoesCustomizacao && (
          <Helmet>
            <title>
              {this.state.opcoesCustomizacao.titulo || "Cadastre-se"}
            </title>
            {this.state.opcoesCustomizacao.favicon && (
              <link rel="icon" href={this.state.opcoesCustomizacao.favicon} />
            )}
            {this.state.opcoesCustomizacao.favicon && (
              <link
                rel="apple-touch-icon"
                href={this.state.opcoesCustomizacao.favicon}
              />
            )}
            {this.state.opcoesCustomizacao.description && (
              <meta
                name="description"
                content={this.state.opcoesCustomizacao.description}
              />
            )}
          </Helmet>
        )}
        {(Template || false) && (
          <Template {...this.state.opcoesCustomizacao}>
            <FormularioLead
              submitHandler={this.submitHandler}
              camposQuestionario={this.state.camposQuestionario}
              camposObrigatorios={this.state.camposObrigatorios}
              corBotao={this.state.opcoesCustomizacao.cor_botao}
              corFundo={this.state.opcoesCustomizacao.cor_fundo_formulario}
              corBorda={this.state.opcoesCustomizacao.cor_borda_formulario}
              tamanhoBorda={
                this.state.opcoesCustomizacao.espesura_borda_formulario
              }
              corTextoBotao={this.state.opcoesCustomizacao.cor_texto_botao}
            />
          </Template>
        )}
      </Suspense>
    );
  }
}

export default LandingPage;
