import React from 'react';
import './App.css';
import LandingPage from "./landing-page/landing-page";
import DommusPromiseLoading from "./dommus-loading/DommusPromiseLoading";

function App() {
  return (
    <div className="full-height">
      <DommusPromiseLoading />
      <LandingPage></LandingPage>
    </div>
  );
}

export default App;
