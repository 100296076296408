import axios from "axios";

const apiUri = "https://api.leads.dommus.com.br/landing-pages";

const CrmClientService = {
  getDadosCampanha: function (hash) {
    return axios({
      method: "get",
      url: apiUri + "/" + hash,
      responseType: "json",
      headers: {
        Authorization:
          "327cb6c3f2e06ee9b10e59063d9a27f8e32cc9923e66535e6e3d7a8be8e20ca7",
      },
    });
  },

  inserirLead: function (inquilino, lead) {
    return axios({
      method: "post",
      url: apiUri,
      data: {
        lead: lead,
        id_inquilino: inquilino,
      },
      responseType: "json",
      headers: {
        Authorization:
          "327cb6c3f2e06ee9b10e59063d9a27f8e32cc9923e66535e6e3d7a8be8e20ca7",
      },
    });
  },
};

export default CrmClientService;
